import React from "react";
import { css } from "@emotion/react";
import Image from "gatsby-image";
import { Div, Container, Section } from "./Markup";
import { TitleMd, Highlight } from "./Typography";
import Tag from "./Tag"
import Button from "./Button";
import { Link } from "gatsby";
import Thumbnail from "./Thumbnail";
import COLORS from "../styles/colors"

const Featured = ({ title, slug, tag, featuredImage, highlight }) => {
  return (
    <Container>
      <Div
        mb="md"
        pb="md"
        css={css`
          border-bottom: 1px solid ${COLORS.black};
        `}
      >
        <Div
          css={css`
            position: relative;
          `}
        >
          <Link to={`/${slug}`}>
            <Tag text={tag}/>
            <Div
              css={css`
                position: relative;
                overflow: hidden;
                height: 60vh;
              `}
            >
              <Image
                fluid={featuredImage.fluid}
                css={css`
                  position: absolute;
                  top: 50%;
                  left: 50%;
                  min-height: 100%;
                  min-width: 100%;
                  transform: translate3d(-50%, -50%, 0);
                `}
              ></Image>
            </Div>
          </Link>
        </Div>
        <Div
          flex
          css={css`
            align-items: flex-start;
            justify-content: flex-end;
          `}
        >
          <Div
            pt="sm"
            css={css`
              @media (min-width: 992px) {
                width: 50%;
              }

              @media (min-width: 1200px) {
                width: 42.57%;
                padding-left: 18px;
              }
            `}
          >
            <Link to={`/${slug}`}>
              <TitleMd>{title}</TitleMd>
              <Highlight mt="xs" mb="md">{highlight}</Highlight>
              <Button url={`/${slug}`} text="прочети"/>
            </Link>
          </Div>
        </Div>
      </Div>
    </Container>
  );
};

const GridItem = ({ slug, title, tag, highlight, featuredImage }) => {
  return (
    <Thumbnail
      title={title}
      slug={slug}
      tag={tag}
      highlight={highlight}
      image={featuredImage}
    />
  );
};

const Grid = ({ textures }) => {
  return (
    <div>
      <Section pt="lg" pb="lg">
        <Featured {...textures[0]} />
        <Container>
          <Div
            flex
            ai="flex-start"
            css={css`
              justify-content: space-between;
              flex-wrap: wrap;
              margin: 0px -18px;
            `}
          >
            {textures.map((texture, index) => {
              if (index > 0) {
                return <GridItem key={texture.id} {...texture} />;
              } else {
                return null
              }
            })}
          </Div>
        </Container>
      </Section>
    </div>
  );
};

export default Grid;
