import React from "react";
import { graphql } from "gatsby";

import Grid from "../components/Grid";
import { forEach } from "lodash";


const IndexPage = ({ data }) => {
  const { allDatoCmsTextura } = data;
  let textures = [];
  forEach(allDatoCmsTextura.edges, edge => {
    textures.push(edge.node);
  });
  return (
    <div>
      <Grid textures={textures} />
    </div>
  );
};

export default IndexPage;

export const query = graphql`
  query ProjectQuerySorted {
    allDatoCmsTextura(sort: {fields: meta___createdAt, order: DESC}) {
      edges {
        node {
          id
          slug
          title
          tag
          featuredImage {
            fluid {
              aspectRatio
              src
              srcSet
            }
          }
          highlight
          content {
            ... on DatoCmsTextBlock {
              id
              text
            }
            ... on DatoCmsImageBlock {
              id
              image {
                fluid {
                  aspectRatio
                  src
                  srcSet
                }
              }
              caption
            }
          }
        }
      }
    }
  }
`;
