import React from "react"
import { css } from "@emotion/react";
import { Div } from "./Markup";
import { Small } from "./Typography";
import COLORS from "../styles/colors"


const Tag = ({text}) => {
  // set default text value
  if (typeof text === "undefined" || text === null || !text) {
    text = "текстура";
  }
    return (
      <Div
        css={css`
        background: ${COLORS.black};
        color: white;
        position: absolute;
        top: 9px;
        left: 9px;
        padding: 9px 18px;
        z-index: 10;
        `}
      >
        <Small>{text}</Small>
      </Div>
    );
    
  };
  
 export default Tag;
    