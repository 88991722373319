import React, { useEffect, useState } from "react";
import { css } from "@emotion/react";
import Image from "gatsby-image";
import { Div } from "./Markup";
import { TitleMd, Highlight } from "./Typography";
import Tag from "./Tag"
import { Link } from "gatsby";

// define constants (28.57, 125)
const SIZE = {w: 32.57, ratio: 88};
const MAX_WIDTH = 1400;


const Thumbnail = ({ image, title, tag, slug, highlight }) => {
  
  const [height, setHeight] = useState(0);

  useEffect(() => {
    if (typeof window === "object") {
      let cw = 0;
      const ww = window.innerWidth;
      
      // when window is thinner then max width take this length minus 30px
      if (ww < MAX_WIDTH) {
        cw = ww - 30;

      // otherwise take the maximum width
      } else {
        cw = MAX_WIDTH;
      }

      // check how it was before using single size!!
      const w = (SIZE.w * cw) / 100;
      const h = (SIZE.ratio * w) / 100;
      setHeight(h);
    }
  }, []);

  return (
    <Div
      mb="lg"
      css={css`
        position: relative;
        padding: 0px 18px;
        justify-content: space-between;
        width: 100vh;

        @media (min-width: 992px) {
          min-width: ${SIZE.w}%;
          width: ${SIZE.w}px;
        }
      `}
    >
      <Link
        to={`/${slug}`}
        css={css`
          position: relative;
          overflow: hidden;
          height: 33.33vh;
          display: block;
          margin-bottom: 15px;

          @media (min-width: 992px) {
            height: ${height}px;
            margin-bottom: 30px;
          }
        `}
      >
        <Image
          fluid={image.fluid}
          css={css`
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate3d(-50%, -50%, 0);
            min-width: 100%;
            min-height: 100%;
          `}
        />
        <Tag text={tag}/>
      </Link>
      <Link to={`/${slug}`}>
        <Div
          css={css`
            align-items: center;
            justify-content: center;
            text-align: center;
            display: flex;
            flex-direction: column;

            @media (max-width: 768px) {
              padding-bottom: 24px
            }
          `}
        >
          <TitleMd>{title}</TitleMd>
          <Highlight mt="xs">{highlight}</Highlight>
        </Div>
      </Link>
    </Div>
  );
};

export default Thumbnail;
